import { LayerBookingItem } from '@/api/services/booking.service';
import { PointType } from '@/api/services/layer.service';
import { MetablockNodes } from '@/api/services/project.service';
import { INavigation } from '@/hooks/settings/settings.interface';
import { useMapStore } from '@/stores/mapStore';
import { memo, useMemo } from 'react';
import { Arrow, Group, Layer, Line } from 'react-konva';
import { getMapCoord } from '../polygon/Polygon';
import { AnimatedPoint } from './Point';
import { useRoute } from './use-routes';

interface PointsLayerProps {
  colors?: any;
  userDepartmentFieldId?: string | null;
  userFulltimeFieldId?: string | null;
  options?: {
    labelSize: number;
    fontSize: number;
    color: string;
    borderWidth: number;
    wrapText: boolean;
  };
  points?: PointType[];
  nodes?: MetablockNodes;
  bookings?: LayerBookingItem[];
  url: string;
  sourceType: 'anonymous' | 'use-credentials';
  navigation: INavigation;
  metadata: any;
}

const defaultOptions = {
  labelSize: 0.015,
  fontSize: 12,
  color: '#ffffff',
  borderWidth: 0.1,
  wrapText: false,
};

const getBookable = (pluginData) => {
  let isBookable = false;

  Object.values(pluginData).forEach((obj: any) => {
    const hasProp = Object.keys(obj).includes('bookable');

    if (hasProp) {
      isBookable = obj['bookable'];
    }
  });

  return isBookable;
};

const PointsLayer: React.FC<PointsLayerProps> = ({
  userDepartmentFieldId,
  userFulltimeFieldId,
  colors,
  nodes = {},
  options = defaultOptions,
  bookings = [],
  points = [],
  url,
  sourceType,
  navigation,
  metadata,
}) => {
  const [width, height] = useMapStore((state) => state.size);
  const layers = useMapStore((state) => state.layers);
  const isBookableVisible = useMemo(() => layers['bookable'], [layers]);
  const isNotBookableVisible = useMemo(() => layers['not-bookable'], [layers]);

  const navigaiontTypes = navigation.keypoint.map((k) => k.name);

  const displayPoints = useMemo(() => {
    return points.filter((point) => {
      const isBookable = getBookable(point.plugin_data);
      const isKeypoint = navigaiontTypes.includes(point.type_name);

      if (isBookableVisible && isBookable) return true;
      return isNotBookableVisible && !isBookable && isKeypoint
        ? navigation.keypointsVisible
        : true;
    });
  }, [
    isBookableVisible,
    isNotBookableVisible,
    points,
    navigaiontTypes,
    navigation,
  ]);

  const route = useRoute({
    navigation,
    metadata,
    points,
  });

  if (width == 0 || height == 0) return null;

  return (
    <Layer>
      {displayPoints.map((point) => (
        <AnimatedPoint
          colors={colors}
          key={point.id}
          id={point.id}
          point={point}
          options={options}
          nodes={nodes}
          bookings={bookings}
          userDepartmentFieldId={userDepartmentFieldId}
          userFulltimeFieldId={userFulltimeFieldId}
          url={url}
          sourceType={sourceType}
        />
      ))}

      {!!route?.length && <RouteArrow points={route} />}
    </Layer>
  );
};

export default memo(PointsLayer);

// PointsLayer.whyDidYouRender = true

const RouteArrow = ({ points }) => {
  const [width, height] = useMapStore((state) => state.size);
  const routePoints = useMemo(
    () =>
      points.map(([x, y]) => [getMapCoord(width, x), getMapCoord(height, y)]),
    [points, width, height],
  );

  // Получаем координаты конца и предпоследней точки
  const [endX, endY] = routePoints[points.length - 1];
  const [secondLastX, secondLastY] = routePoints[points.length - 2];

  // Вычисляем направление стрелки
  const angle = Math.atan2(endY - secondLastY, endX - secondLastX);

  return (
    <Group>
      <Line
        points={routePoints.flat()} // Преобразуем массив точек в одномерный массив
        stroke="red"
        strokeWidth={4}
      />
      <Group
        rotation={angle * (180 / Math.PI)}
        x={endX}
        y={endY}
      >
        <Arrow
          points={[0, 0]}
          fill="red"
          stroke="red"
        />
      </Group>
    </Group>
  );
};
