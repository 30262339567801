import React from 'react';

const BookingIcon = ({ width = 30, height = 31 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="hover-color"
        d="M5.62527 3.06531H6.12527V2.56531V1.62781C6.12527 1.51178 6.17137 1.4005 6.25342 1.31845C6.33546 1.2364 6.44674 1.19031 6.56277 1.19031C6.67881 1.19031 6.79009 1.2364 6.87213 1.31845C6.95418 1.4005 7.00027 1.51178 7.00027 1.62781V2.56531V3.06531H7.50027H22.5003H23.0003V2.56531V1.62781C23.0003 1.51177 23.0464 1.40049 23.1284 1.31845C23.2105 1.2364 23.3217 1.19031 23.4378 1.19031C23.5538 1.19031 23.6651 1.2364 23.7471 1.31845C23.8292 1.40049 23.8753 1.51177 23.8753 1.62781V2.56531V3.06531H24.3753H26.2503C27.1122 3.06531 27.9389 3.40772 28.5484 4.01721C29.1579 4.6267 29.5003 5.45335 29.5003 6.31531V7.69031H0.500275V6.31531C0.500275 5.45335 0.842684 4.6267 1.45218 4.01721C2.06167 3.40772 2.88832 3.06531 3.75027 3.06531H5.62527ZM12.2677 18.4232L12.2676 18.423C11.9978 18.1532 11.6318 18.0016 11.2503 18.0016C10.8687 18.0016 10.5028 18.1532 10.233 18.423C9.96317 18.6928 9.81159 19.0587 9.81159 19.4403C9.81159 19.8219 9.96317 20.1878 10.233 20.4576L13.045 23.2697C13.1786 23.4035 13.3372 23.5097 13.5118 23.5822C13.6865 23.6547 13.8737 23.692 14.0628 23.692C14.2519 23.692 14.4391 23.6547 14.6137 23.5822C14.7884 23.5097 14.947 23.4035 15.0805 23.2697L20.7051 17.6451C20.9749 17.3753 21.1265 17.0094 21.1265 16.6278C21.1265 16.2462 20.9749 15.8803 20.7051 15.6105C20.4353 15.3407 20.0693 15.1891 19.6878 15.1891C19.3062 15.1891 18.9403 15.3407 18.6705 15.6105L18.6704 15.6106L14.0629 20.2199L12.2677 18.4232ZM0.500275 26.9403V10.5653H29.5003V26.9403C29.5003 27.8023 29.1579 28.6289 28.5484 29.2384C27.9389 29.8479 27.1122 30.1903 26.2503 30.1903H3.75027C2.88832 30.1903 2.06167 29.8479 1.45218 29.2384C0.842684 28.6289 0.500275 27.8023 0.500275 26.9403Z"
        fill="#2C2C2C"
        stroke="#2C2C2C"
      />
    </svg>
  );
};

export default BookingIcon;
