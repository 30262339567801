import { sizes } from '@/ui/media';
import { DefaultTheme } from 'styled-components';

export const blueTheme: DefaultTheme = {
  grid: {
    gutter: 8,
    breakpoints: sizes,
  },
  palette: {
    default: {
      main: '#fff',
      text: '#2C2C2C',
    },
    accent: {
      main: '#F8DC4D',
    },
    primary: {
      main: '#079DAC',
    },
    background: {
      primary: '#079DAC',
      default: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#000',
      label: '#fff',
      input: 'rgba(0, 0, 0, 0.25)',
    },
  },
};

export default blueTheme;
