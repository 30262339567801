import React from 'react';

const InfoIcon = ({ width = 21, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_502_85)">
        <path
          d="M10.5 2.4424C8.28987 2.4424 6.17026 3.32038 4.60745 4.88318C3.04465 6.44598 2.16668 8.5656 2.16668 10.7757C2.16668 12.9859 3.04465 15.1055 4.60745 16.6683C6.17026 18.2311 8.28987 19.1091 10.5 19.1091C12.7101 19.1091 14.8298 18.2311 16.3926 16.6683C17.9554 15.1055 18.8333 12.9859 18.8333 10.7757C18.8333 8.5656 17.9554 6.44598 16.3926 4.88318C14.8298 3.32038 12.7101 2.4424 10.5 2.4424ZM0.0833435 10.7757C0.0833435 5.02261 4.74689 0.35907 10.5 0.35907C16.2531 0.35907 20.9167 5.02261 20.9167 10.7757C20.9167 16.5289 16.2531 21.1924 10.5 21.1924C4.74689 21.1924 0.0833435 16.5289 0.0833435 10.7757Z"
          fill="#F3BB5E"
        />
        <path
          d="M10.5 8.69242C10.7763 8.69242 11.0412 8.80217 11.2366 8.99752C11.4319 9.19287 11.5417 9.45782 11.5417 9.73409V15.9841C11.5417 16.2604 11.4319 16.5253 11.2366 16.7207C11.0412 16.916 10.7763 17.0258 10.5 17.0258C10.2237 17.0258 9.95878 16.916 9.76343 16.7207C9.56808 16.5253 9.45833 16.2604 9.45833 15.9841V9.73409C9.45833 9.45782 9.56808 9.19287 9.76343 8.99752C9.95878 8.80217 10.2237 8.69242 10.5 8.69242ZM12.0625 6.08826C12.0625 6.50266 11.8979 6.90009 11.6049 7.19311C11.3118 7.48614 10.9144 7.65076 10.5 7.65076C10.0856 7.65076 9.68817 7.48614 9.39515 7.19311C9.10212 6.90009 8.9375 6.50266 8.9375 6.08826C8.9375 5.67386 9.10212 5.27643 9.39515 4.9834C9.68817 4.69038 10.0856 4.52576 10.5 4.52576C10.9144 4.52576 11.3118 4.69038 11.6049 4.9834C11.8979 5.27643 12.0625 5.67386 12.0625 6.08826Z"
          fill="#F3BB5E"
        />
      </g>
      <defs>
        <clipPath id="clip0_502_85">
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(0 0.310303)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
