import React from 'react';
import styled from 'styled-components';

const MobileReportItem: React.FC<any> = ({ data, columns }) => {
  return (
    <ItemWrapper>
      <PropsWrapper>
        {columns.map((item) => (
          <Property key={item.key}>
            <PropName>{item.label}</PropName>
            <PropValue>{data[item.key]}</PropValue>
          </Property>
        ))}
      </PropsWrapper>
    </ItemWrapper>
  );
};

export default MobileReportItem;

const ItemWrapper = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 15px 10px;
  color: #000;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const PropsWrapper = styled.div`
  margin: 6px 0 12px 0;
`;

const PropName = styled.div`
  width: 100%;
`;
const PropValue = styled.div`
  width: 100%;
  font-weight: 700;
`;

const Property = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;
