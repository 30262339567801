import $config from '@/utils/Config';

export const useSettings = () => {
  const settings = $config.settings;

  return {
    data: settings,
  };
};

export default useSettings;
