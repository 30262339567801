import Close from '@/components/Close';
import PlusIcon from '@/components/icons/PlusIcon';
import { useGlobalStore } from '@/stores/globalStore';
import { RolesEnum, useUserStore } from '@/stores/userStore';
import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@/ui/media';
import { translate } from '@/i18n';

const Sidebar = ({
  children,
  minWidth = 1,
  maxWidth = 1024,
  ...otherProps
}) => {
  return (
    <Wrapper
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      {...otherProps}
    >
      {children}
    </Wrapper>
  );
};

export default Sidebar;

const Wrapper = styled.div<{ $minWidth?: number; $maxWidth?: number }>`
  width: 100%;
  max-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth}px;

      ${media.lg`
            min-width: 100%;
        `}
    `}

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth}px;
      ${media.lg`
            min-width: 100%;
        `}
    `}
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.text.primary};
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header: React.FC<{
  title: string;
  showAdd?: boolean;
  onAdd?: (...args: any) => void;
}> = ({ title, onAdd, showAdd }) => {
  // data selectors
  const setSelector = useGlobalStore((state) => state.setSelector);
  const role = useUserStore((state) => state.role);

  // const showHeader = showAdd || role === RolesEnum.Admin
  // const showHeader = onAdd || role === RolesEnum.Admin
  const showHeader = typeof onAdd === 'function';

  return (
    <TitleContainer>
      <TitleWrapper>
        <Sidebar.Title>{translate(title)}</Sidebar.Title>
        {showHeader && (
          <Add onClick={onAdd}>
            <PlusIcon
              size={16}
              color="white"
            />
          </Add>
        )}
      </TitleWrapper>
      <Close onClick={() => setSelector(null)} />
    </TitleContainer>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Add = styled.div`
  margin-left: 12px;
  width: 40px;
  height: 40px;
  border: 1px solid #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }
`;

const Content = styled.div`
  padding: 1rem 0;
`;

const TitleContainer = styled(Box)`
  flex-shrink: 0;
  margin-bottom: 10px;
`;

Sidebar.Title = Title;
Sidebar.Box = Box;
Sidebar.Header = Header;
Sidebar.Content = Content;
