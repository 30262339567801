import React from 'react';
import { FormattedMessage } from 'react-intl';

const translate = (id = '', value = {}) =>
  id && (
    <FormattedMessage
      id={id}
      values={{ ...value }}
    />
  );

export default translate;
