export const bookingErrors = {
  resolver_state_1: 'Текущая бронь пересекается с уже существующей бронью',
  resolver_state_2: 'Текущая бронь пересекается с чужой бронью',
  resolver_state_4: 'Невозможно создать бронь для данного объекта',
  resolver_state_8:
    'Вы не можете создавать новые брони для данного типа объектов',
  resolver_state_10: 'Объект для бронирования не найден',
};

export const bookingTypes = [
  { value: '1', label: 'common' },
  { value: '3', label: 'constant' },
  { value: '2', label: 'recurrent' },
];

export const bookingInterval = 30 * 60 * 1000;

export enum MetadataPluginsEnum {
  Booking = '53d02367136147b8b5187d109256ce74',
}
