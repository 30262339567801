import IFrameAuth from '@/components/IFrameAuth';
import NotFound from '@/components/NotFound';
import ProtectedRoutes from '@/components/ProtectedRoutes';
import LogoutPage from '@/pages/logout';
import '@/settings.json';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoginContainer from './LoginContainer';
import ProjectContainer from './ProjectContainer';
import $config from '@/utils/Config';

const AppContainer = () => {
  const isSingle = $config.settings.single || false;

  return (
    <Router>
      <Routes>
        {isSingle ? (
          <>
            <Route
              path="*"
              element={<NotFound />}
            />
            <Route
              path="/"
              element={<IFrameAuth />}
            >
              <Route
                path="/login"
                element={<LoginContainer />}
              />
              <Route element={<ProtectedRoutes />}>
                <Route
                  index
                  element={<ProjectContainer />}
                />
              </Route>
              <Route
                path="/logout"
                element={<LogoutPage />}
              />
            </Route>
          </>
        ) : (
          <>
            <Route element={<IFrameAuth />}>
              <Route
                path="/login"
                element={<LoginContainer />}
              />
              <Route element={<ProtectedRoutes />}>
                <Route
                  path="/project/:workspaceId/:projectId"
                  element={<ProjectContainer />}
                />
              </Route>
              <Route
                path="/logout"
                element={<LogoutPage />}
              />
              <Route
                index
                element={<NotFound />}
              />
            </Route>
          </>
        )}
      </Routes>
    </Router>
  );
};

export default AppContainer;
