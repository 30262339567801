import { useGlobalStore } from '@/stores/globalStore';
import { useMapStore } from '@/stores/mapStore';
import { useEffect } from 'react';
import MapLoader from './MapLoader';

const MapStatus = () => {
  const activeLayer = useGlobalStore((state) => state.activeLayer);
  const isLoading = useMapStore((state) => state.isLoading);
  const setIsLoading = useMapStore((state) => state.setIsLoading);

  // useEffect(() => {
  //     setIsLoading(true)
  // }, [activeLayer])

  return <MapLoader isLoading={isLoading} />;
};

export default MapStatus;
