import { ProjectService } from '@/api/services/project.service';
import { useToast } from '@/components/shared/toast/useToast';
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useProject } from '@/hooks/useProject';
import { translate } from '@/i18n';
import { useGlobalStore } from '@/stores/globalStore';
import { useProjectStore } from '@/stores/projectStore';
import { lowerCaseString } from '@/utils/helpers/string.helpers';
import flattenDeep from 'lodash/flattenDeep';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  SearchBookingsIcon,
  SearchLayersIcon,
  SearchPlacesIcon,
  SearchUsersIcon,
} from '../../../../icons/SearchIcons';
import { filterResults } from '../Search';
import SearchItem from '../SearchItem';
import SearchItemV2 from '../SearchItemV2';
import { filterResultsV2 } from '../SearchV2';
import SearchField from './ui/SearchField';

const MobileSearch = ({ handleSearchClose }) => {
  const { workspaceId, projectId } = useProject();
  const { enqueueToast } = useToast();
  const searchRef = useRef(null);
  const fullMode = useProjectStore((state) => state.fullMode);
  const searchVersion = useSettingsSelector(
    (settings) => settings.search,
    process.env.SEARCH_VERSION == 'v2' ? 2 : 1,
  );

  // local state
  const [bookings, setBookings] = useState(true);
  const [places, setPlaces] = useState(true);
  const [user, setUser] = useState(true);
  const [layers, setLayers] = useState(true);
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  // data selectors
  const setSelector = useGlobalStore((state) => state.setSelector);

  // outside element click listener
  useOnClickOutside(searchRef, () => setFocus(false));

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.search({
        workspaceId,
        projectId,
        term: String(search),
        point: places,
        user: user || bookings,
        layer: layers,
      });

      if (response && response.data) {
        const { search_result } = response.data;
        const results: any[] = flattenDeep(search_result);

        let result = results
          .map((res) => {
            if (res.type === 'user') {
              res.name = res.display;
              const tempRes = res;

              const pathBookings =
                Object.keys(res).find((key) => key.includes('locations')) || '';
              const userBookings = JSON.parse(res[pathBookings]);

              if (
                bookings &&
                userBookings &&
                userBookings.some((book) =>
                  book?.place_name?.includes(search.trim()),
                )
              ) {
                res = null;
              }

              if (bookings) {
                const isCorrectSearchBook = lowerCaseString(
                  tempRes.name,
                ).includes(lowerCaseString(search));

                if (!isCorrectSearchBook) {
                  return res;
                }

                const bookings = userBookings.map((booking) => ({
                  type: 'booking',
                  user: tempRes.display,
                  user_id: tempRes.id,
                  name: tempRes.display,
                  id: booking.booking_id,
                  type_uid: booking.place_type,
                  place: booking.place_name,
                  begin: booking.begin,
                  end: booking.end,
                  layer_id: booking.layer_id,
                  place_id: booking.place_id,
                }));

                if (!user) {
                  return bookings;
                }

                return [res, ...bookings];
              }

              return res;
            }

            return res;
          })
          .filter((v) => v);

        if (!user) {
          result = result.filter((item) => item.type != 'user');
        }

        const searchResults = flattenDeep(result);

        setData(filterResults(search, searchResults, bookings));
      }
    } catch (e) {
      enqueueToast(
        { title: 'Ошибка', message: 'Не удалось загрузить данные поиска' },
        { variant: 'error' },
      );
    }
    setLoading(false);
  };

  const fetchDataV2 = async () => {
    setLoading(true);
    try {
      const searchObj = {
        workspaceId,
        projectId,
        // TODO: Fix me plz im broken
        term: search,
        point: places,
        user: fullMode ? user || bookings : false,
        layer: layers,
      };
      // const response = await ProjectService.search(searchObj)
      const responseV2 = await ProjectService.searchV2(searchObj);

      if (responseV2 && responseV2.data) {
        const { search_result } = responseV2.data;
        const results: any[] = flattenDeep(search_result);

        const result = results
          .map((res) => {
            if (res['index-type'] === 'user') {
              res.name = res.display;
              const tempRes = res;

              let pathKey = '';

              Object.entries(res).forEach(([key, value]) => {
                const isValidObject = value && typeof value === 'object';
                if (!isValidObject) return;
                // TODO: make type-guard in newer version
                const isValidFields = value['locations'];
                if (isValidFields) {
                  pathKey = key;
                }
                return;
              });

              const userBookings = res[pathKey]['locations'] || [];

              if (
                bookings &&
                userBookings &&
                userBookings.some((book) =>
                  book?.place_name?.includes(search.trim()),
                )
              ) {
                res = null;
              }

              if (bookings) {
                const isCorrectSearchBook = lowerCaseString(
                  tempRes.name,
                ).includes(lowerCaseString(search));
                if (!isCorrectSearchBook && !user) return null;
                if (!isCorrectSearchBook) {
                  return res;
                }

                const bookings = userBookings?.map((booking) => ({
                  'index-type': 'booking',
                  user: tempRes.display,
                  user_id: tempRes.id,
                  name: tempRes.display,
                  id: booking.booking_id,
                  type_uid: booking.place_type,
                  place: booking.place_name,
                  begin: booking.begin,
                  end: booking.end,
                  layer_id: booking.layer_id,
                  place_id: booking.place_id,
                }));

                if (!user) {
                  return bookings;
                }

                // return [res, ...bookings]
                return res;
              }

              if (!user) return null;

              return res;
            }

            return res;
          })
          .filter((v) => v);

        const searchResults = flattenDeep(result);

        setData(filterResultsV2(search, searchResults, bookings));
      }
    } catch (e) {
      console.error(e);

      enqueueToast(
        { title: 'Ошибка', message: 'Не удалось загрузить данные поиска' },
        { variant: 'error' },
      );
    }
    setLoading(false);
  };

  const fetchSearchFn = searchVersion === 2 ? fetchDataV2 : fetchData;
  const SearchComponent = searchVersion === 2 ? SearchItemV2 : SearchItem;

  useEffect(() => {
    if (search) {
      fetchSearchFn();
    }
  }, [search, layers, user, places, bookings]);

  return (
    <Wrapper onFocus={() => setFocus(true)}>
      <SearchBox ref={searchRef}>
        <SearchFieldWrapper>
          <SearchField
            value={search}
            onChange={(value) => setSearch(value)}
          />
          <FiltersWrapper>
            {fullMode && (
              <FiltersItem>
                <SearchBookingsIcon
                  active={bookings}
                  onClick={() => setBookings(!bookings)}
                />
              </FiltersItem>
            )}
            <FiltersItem>
              <SearchPlacesIcon
                active={places}
                onClick={() => setPlaces(!places)}
              />
            </FiltersItem>
            {fullMode && (
              <FiltersItem>
                <SearchUsersIcon
                  active={user}
                  onClick={() => setUser(!user)}
                />
              </FiltersItem>
            )}
            <FiltersItem>
              <SearchLayersIcon
                active={layers}
                onClick={() => setLayers(!layers)}
              />
            </FiltersItem>
          </FiltersWrapper>
        </SearchFieldWrapper>

        {search && (
          <SearchResults>
            {data.length && !isLoading
              ? data.map((item) => (
                  <SearchComponent
                    key={item.name + item.id}
                    handleSearchClose={null}
                    data={item}
                  />
                ))
              : null}
            {!data.length && !isLoading ? (
              <NotFound>{translate('no-results')}</NotFound>
            ) : null}
            {isLoading && <NotFound>{translate('loading')}...</NotFound>}
          </SearchResults>
        )}
      </SearchBox>
    </Wrapper>
  );
};

export default MobileSearch;

const SearchFieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-shrink: 0;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, .25) 0px 4px 4px;
	padding: 10px;
	padding-top: 16px;
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const FiltersItem = styled.div`
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const NotFound = styled.div`
	height: 100%;
	font-size: 12px;
	line-height: 16px;
	color: #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px;
`

const SearchResults = styled.div`
	background: #ffffff;
	top: 100%;
	width: 100%;
	min-height: 200px;
	height: 100%;
	box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 10px;
`

const SearchBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	flex-direction: column;
	overflow-y: auto;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  input {
    margin-right: 0.8rem;
  }
`;
