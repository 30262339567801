import { MeData } from '../api/user';
import { create } from 'zustand';
import { IUser } from '@/api/services/user.service';

export enum RolesEnum {
  Admin = 'admin',
  User = 'client',
  None = 'none',
}

type UserState = {
  isAuthenticated: boolean;
  user: IUser | null;
  role: RolesEnum;
  categories: string[];
  authRef: string | null;
  setCategories: (categories: string[]) => void;
  setRole: (role: RolesEnum) => void;
  setAuthenticated: (isAuthenticated: boolean) => void;
  setUser: (user: IUser | null) => void;
  setAuthRef: (authRef: string | null) => void;
};

export const useUserStore = create<UserState>((set) => ({
  isAuthenticated: false,
  user: null,
  role: RolesEnum.User,
  categories: [],
  authRef: null,
  setCategories: (categories) => set(() => ({ categories })),
  setRole: (role) => set(() => ({ role })),
  setAuthenticated: (isAuthenticated) => set(() => ({ isAuthenticated })),
  setUser: (user) =>
    set(() => ({ user, isAuthenticated: user ? true : false })),
  setAuthRef: (authRef) => set(() => ({ authRef })),
}));
