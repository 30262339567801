import React from 'react';
import styled from 'styled-components';
import { translate } from '@/i18n';

type GridAnnotationProps = {
  color: string;
  text: string;
};

const GridAnnotation: React.FC<GridAnnotationProps> = ({ color, text }) => {
  return (
    <Annotation>
      <AnnotationColor $color={color} />
      <AnnotationText>{translate(text)}</AnnotationText>
    </Annotation>
  );
};

export default GridAnnotation;

const AnnotationText = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
  color: #000000;
`;

const AnnotationColor = styled.div<{ $color?: string }>`
  width: 14px;
  height: 14px;
  background: ${({ $color }) => $color};
  border-radius: 2px;
  margin-right: 8px;
`;

const Annotation = styled.div`
  display: flex;
  border-radius: 2px;
`;
