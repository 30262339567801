import { useQuery } from 'react-query';
import { useProject } from '@/hooks/useProject';
import { LayerService } from '../services/layer.service';
import { useToast } from '@/components/shared/toast/useToast';

export const useLayerInfo = (layer?: number | null) => {
  const { workspaceId, projectId } = useProject();
  const { enqueueToast } = useToast();

  const { data, isLoading, refetch } = useQuery(
    ['layer_info', layer],
    () =>
      LayerService.getLayerInfo({
        workspaceId,
        projectId,
        layerId: Number(layer),
      }),
    {
      enabled: !!workspaceId && !!projectId && !!layer,
      select: ({ data }) => ({
        info: data.layer_info,
      }),
      onError: () => {
        enqueueToast(
          { title: 'Ошибка!', message: 'Не удалось загрузить данные о слое' },
          { variant: 'error' },
        );
      },
    },
  );

  return { data, isLoading, refetch };
};
