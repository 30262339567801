import { createGlobalStyle } from 'styled-components';

const DatePickerStyles = createGlobalStyle`
    .react-datepicker-popper {
        z-index: 1000;
    }

    .react-datepicker {
        font-size: 1.4rem;
    }

    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
        font-size: 1.6rem;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: 2.7rem;
        line-height: 2.7rem;
    }
`;

export default DatePickerStyles;
