import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import Toast from './Toast';

const ToastContainer = ({ toasts }) => {
  return ReactDOM.createPortal(
    <Wrapper>
      {toasts.map((item) => (
        <Toast
          key={item.id}
          id={item.id}
          options={item.options}
          content={item.content}
        />
      ))}
    </Wrapper>,
    document.body,
  );
};

export default ToastContainer;

const Wrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 0;
  z-index: 30001;
`;
