import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import translate from './../../../i18n/translate';

const MobileBookingItem: React.FC<any> = ({
  fio,
  name,
  type,
  date,
  handleEdit = null,
  handleDelete = null,
  handleSeatView = null,
}) => {
  return (
    <ItemWrapper>
      {fio && <Name>{fio}</Name>}
      <Name>{name}</Name>
      <PropsWrapper>
        <Property>
          <PropName>{translate('type')}</PropName>
          <PropValue>{type}</PropValue>
        </Property>
        <Property>
          <PropName>{translate('date')}</PropName>
          <PropValue>{date}</PropValue>
        </Property>
      </PropsWrapper>
      {handleEdit || handleDelete ? (
        <FormControls>
          {handleSeatView && (
            <PrimaryButton onClick={handleSeatView}>
              {translate('show-on-map')}
            </PrimaryButton>
          )}
          {handleEdit && (
            <PrimaryButton onClick={handleEdit}>
              {translate('edit')}
            </PrimaryButton>
          )}
          {handleDelete && (
            <DefaultButton onClick={handleDelete}>
              {translate('delete')}
            </DefaultButton>
          )}
        </FormControls>
      ) : (
        ''
      )}
    </ItemWrapper>
  );
};

export default MobileBookingItem;

export const FormControls = styled.div`
  display: flex;
  align-items: center;
  //justify-content: flex-end;
  row-gap: 16px;
  flex-wrap: wrap;
  margin-top: 24px;
  max-width: 600px;
  width: 100%;

  & > {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const ItemWrapper = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 15px 10px;
  color: #000;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const PropsWrapper = styled.div`
  margin: 6px 0 12px 0;
`;

const PropName = styled.div`
  max-width: 50px;
  width: 100%;
`;
const PropValue = styled.div`
  width: 100%;
  font-weight: 700;
`;

const Property = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #000000;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;
