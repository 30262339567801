import { create } from 'zustand';

type NavigationState = {
  route: { from: any | null; to: any | null };
  setFrom: (from: any | null) => void;
  setTo: (to: any | null) => void;
  clearRoute: () => void;
};

export const useNavigationStore = create<NavigationState>((set) => ({
  route: { from: null, to: null },
  setFrom: (from) =>
    set((prevState) => ({ route: { from, to: prevState.route.to } })),
  setTo: (to) =>
    set((prevState) => ({ route: { to, from: prevState.route.from } })),
  clearRoute: () => set(() => ({ route: { from: null, to: null } })),
}));
