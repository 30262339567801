import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { ProjectService } from '../services/project.service';
import { extractTree } from '@/components/Elements/tree';

export const useTree = () => {
  const { workspaceId, projectId } = useProject();
  const { enqueueToast } = useToast();

  const { data, isLoading, refetch } = useQuery(
    ['tree', workspaceId, projectId],
    () => ProjectService.getTree({ workspaceId, projectId }),
    {
      enabled: !!workspaceId && !!projectId,
      select: ({ data }) => {
        const nodes = extractTree(data.tree);
        const layer = nodes.find((node) => node.parent === null);

        return { nodes, layer, tree: data.tree };

        // return data
      },
      onError: ({ message }) => {
        enqueueToast({ title: 'Ошибка!', message }, { variant: 'error' });
      },
    },
  );

  return { layers: data, isLoading, refetch };
};
