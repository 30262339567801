import React from 'react';

const ObjectsIcon = ({ width = 32, height = 27 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="hover-fill"
        d="M13.1951 3.90311V1.82141C13.1951 1.26913 13.6429 0.821411 14.1951 0.821411H30.5393C31.0916 0.821411 31.5393 1.26913 31.5393 1.82141V10.5254C31.5393 11.0777 31.0936 11.5254 30.5413 11.5254H17.5501C17.0421 11.5254 16.6155 11.143 16.5442 10.6399C16.0589 7.21489 15.2483 5.67559 14.2424 5.17225C13.722 4.91188 13.1951 4.48499 13.1951 3.90311Z"
        fill="#2C2C2C"
      />
      <ellipse
        className="hover-fill"
        cx="22.3672"
        cy="12.8697"
        rx="3.91787"
        ry="3.27207"
        fill="#2C2C2C"
      />
      <rect
        className="hover-fill"
        x="0.43927"
        y="13.0472"
        width="31.1"
        height="3.09452"
        rx="1"
        fill="#2C2C2C"
      />
      <rect
        className="hover-fill"
        x="0.43927"
        y="13.0472"
        width="1.76152"
        height="13.8492"
        rx="0.880762"
        fill="#2C2C2C"
      />
      <path
        className="hover-alt"
        d="M5.90607 7.62369C6.24622 6.02564 7.54611 5.62612 8.15353 5.62612C9.12541 5.48047 11.4701 5.27655 13.0737 5.62612C14.6772 5.9757 15.0377 7.10349 15.0174 7.62369C15.5033 11.3691 16.4995 19.0348 16.5967 19.734C16.7182 20.6079 15.0781 22.1685 14.4707 22.1685C13.8633 22.1685 7.48537 22.3558 6.27052 22.1685C5.29865 22.0187 4.48875 20.4831 4.38751 19.734C4.75197 16.3631 5.56591 9.22175 5.90607 7.62369Z"
        fill="#2C2C2C"
        stroke="white"
      />
      <rect
        className="hover-fill"
        x="9.55063"
        y="22.5338"
        width="2.00449"
        height="2.84087"
        fill="#2C2C2C"
      />
      <rect
        className="hover-fill"
        x="7.78909"
        y="25.3746"
        width="5.70977"
        height="1.52189"
        rx="0.760947"
        fill="#2C2C2C"
      />
      <rect
        className="hover-fill"
        x="18.8442"
        y="17.2903"
        width="12.6951"
        height="9.60625"
        rx="1"
        fill="#2C2C2C"
      />
    </svg>
  );
};

export default ObjectsIcon;
