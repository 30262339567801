import { useParallelBooking } from '@/api/hooks/useParallelBooking';
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useBookingStore } from '@/stores/bookingStore';
import { areIntervalsOverlapping, endOfWeek, startOfWeek } from 'date-fns';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';
import Grid from '@/components/Grid';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BookingDate,
  bookingTypeTranslate,
} from '@/components/layout/Sidebar/Bookings';
import FormLoader from '@/components/ui/form/FormLoader';
import useResponsive from '@/hooks/useResponsive';
import MobileBookingItem from '@/components/layout/Sidebar/MobileBookingItem';
import { useBookingDialogStore } from './BookingModal';
import { formatToISOTimezone } from '@/utils/helpers/dates.helpers';
import { translate } from '@/i18n';
import media from '@/ui/media';

const ParallelBookings = () => {
  const [show, setShow] = useState(false);
  const week = useBookingStore((state) => state.week);

  const weekStart = startOfWeek(week, { weekStartsOn: 1 });
  const weekEnd = endOfWeek(week, { weekStartsOn: 1 });

  const { values } = useFormikContext<any>();
  const bookingId = useBookingDialogStore((state) => state.bookingId);
  const { data } = useParallelBooking({
    nodeId: values.seat.id,
    userId: values.user.id,
    weekStart,
    weekEnd,
  });

  const parallel =
    data?.node_booking_view_v2.items
      .filter((book) => book.reason !== 'my_buisy')
      .filter((slot) =>
        areIntervalsOverlapping(
          {
            start: formatToISOTimezone(slot.start),
            end: formatToISOTimezone(slot.start),
          },
          { start: weekStart, end: weekEnd },
        ),
      ) || [];

  return (
    <ParallelsWrapper>
      <Header>
        <Period>
          {translate('bookings-for-period')}: {format(weekStart, 'dd.MM.yyyy')}{' '}
          - {format(weekEnd, 'dd.MM.yyyy')}
        </Period>
        <ToggleBtn onClick={() => setShow(!show)}>
          ({show ? translate('hide') : translate('show')})
        </ToggleBtn>
      </Header>
      <BookingsWrapper $active={show}>
        {parallel.length ? (
          <div>
            {parallel.map((item) => (
              <ParrallelBook
                key={item.id}
                item={item}
              />
            ))}
          </div>
        ) : (
          <div>{translate('no-parallel-bookings')}</div>
        )}
      </BookingsWrapper>
      {/* <FormLoader isLoading={isLoading} /> */}
    </ParallelsWrapper>
  );
};

export default ParallelBookings;

const ToggleBtn = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #079dac;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const ParrallelBook = ({ item }) => {
  const bookingType =
    item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant';
  const { isDesktop } = useResponsive();

  if (!isDesktop)
    return (
      <ItemWrapper>
        <Name>{item.user_name}</Name>
        <PropsWrapper>
          <Property>
            <PropName>{translate('type')}</PropName>
            <PropValue>{bookingTypeTranslate[bookingType]}</PropValue>
          </Property>
          <Property>
            <PropName>{translate('date')}</PropName>
            <PropValue>
              <ErrorBoundary
                fallback={<div>{translate('date-display-error')}</div>}
              >
                <BookingDate
                  type={bookingType}
                  gap={item.gap}
                  date={{ start: item.start, end: item.end }}
                />
              </ErrorBoundary>
            </PropValue>
          </Property>
        </PropsWrapper>
      </ItemWrapper>
    );

  return (
    <Grid.Line $cols="auto auto 1fr">
      <Grid.Item>{item.user_name}</Grid.Item>
      <Grid.Item>{translate(bookingType)}</Grid.Item>
      <Grid.Item>
        <ErrorBoundary fallback={<div>{translate('date-display-error')}</div>}>
          <BookingDate
            type={bookingType}
            gap={item.gap}
            date={{ start: item.start, end: item.end }}
          />
        </ErrorBoundary>
      </Grid.Item>
    </Grid.Line>
  );
};

const ParallelsWrapper = styled.div`
  padding: 20px 0;
`;

const Period = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    margin-right: 16px;

    ${media.sm`
        font-size: 16px;
    `}
`

const BookingsWrapper = styled.div<{ $active?: boolean }>`
  margin-top: 20px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);

  ${({ $active }) =>
    $active &&
    css`
      max-height: 9999px;
      height: auto;
      transition: all 0.5s cubic-bezier(1, 0, 1, 0);
    `}
`;

const ItemWrapper = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 15px 10px;
  color: #000;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const PropsWrapper = styled.div`
  margin: 6px 0 12px 0;
`;

const PropName = styled.div`
  max-width: 50px;
  width: 100%;
`;
const PropValue = styled.div`
  width: 100%;
  font-weight: 700;
`;

const Property = styled.div`
    display: flex;

    &:not(:last-child) {
        margin-bottom: 6px;
    }
`

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #000000;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;
