import { BookingItem } from '@/api/bookings';
import { useBookings } from '@/api/hooks/useBookings';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import {
  ControlsWrapper,
  FormControls,
} from '@/components/ui/form/FormControls';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { bookingTypeTranslate } from '../../Bookings';
import Pagination from '@/ui/components/Pagination/Pagination';
import useResponsive from '@/hooks/useResponsive';
import { format, parseISO } from 'date-fns';
import { IObjectType, useProjectStore } from '@/stores/projectStore';
import { formatToReport } from '@/utils/helpers/dates.helpers';
import { CSVLink } from 'react-csv';
import { printPDF } from '@/utils/func/print';
import MobileReportItem from './MobileReportItem';
import { translate } from '@/i18n';
import { useIntl } from 'react-intl';

type IColumn = {
  label: string;
  key: string;
  alias: string;
};

const bookingHeaders: IColumn[] = [
  { label: 'ФИО', key: 'user', alias: 'full-name' },
  { label: 'Место', key: 'name', alias: 'object' },
  { label: 'Тип места', key: 'objectType', alias: 'object-type' },
  { label: 'Расположение', key: 'layer', alias: 'location' },
  { label: 'Дата начала', key: 'startDate', alias: 'start-date' },
  { label: 'Дата окончания', key: 'endDate', alias: 'end-date' },
  { label: 'Тип брони', key: 'bookingType', alias: 'booking-type' },
];

const ReportData = ({ settings, onClose, objectTypes, ...props }) => {
  const { data } = useBookings({
    page: 1,
    perPage: 1000,
    moment: 'current',
    day: settings.startDate || new Date(),
    start: settings.startDate,
    end: settings.endDate,
    placeType: settings.objectType || '',
    // place: 'Кинопоиск.Кабинет 1'
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isDesktop } = useResponsive();
  const intl = useIntl();

  const filteredData =
    useMemo(() => {
      const filteredByLayer = settings.layer
        ? data?.items.filter((item) => item.parent_layer_id == settings.layer)
        : data?.items;

      const filteredByBookingType = settings.bookingType
        ? filteredByLayer?.filter((item) => {
            const bookingType = item.rec != '0' ? '2' : item.end ? '1' : '3';
            return bookingType == settings.bookingType;
          })
        : filteredByLayer;

      return filteredByBookingType;
    }, [data]) || [];

  const columns = bookingHeaders
    .filter((header) => settings.cols.includes(header.key))
    .map((item) => ({
      ...item,
      label: intl.formatMessage({ id: item.alias }),
    }));
  const pdfCols = useMemo(
    () => columns.map((col) => ({ header: col.label, dataKey: col.key })),
    [columns],
  );
  const dataItems =
    useMemo(
      () =>
        data?.items.map((item) =>
          BookingMapper.toExport(item, columns, objectTypes),
        ),
      [filteredData],
    ) || [];

  return (
    <ReportDataContainer>
      <Table>
        {isDesktop && (
          <TableHead>
            <tr>
              {columns.map((header) => (
                <TableHeadCell key={header.key}>{header.label}</TableHeadCell>
              ))}
            </tr>
          </TableHead>
        )}

        <TableBody>
          {filteredData && filteredData.length ? (
            filteredData
              .slice((currentPage - 1) * 20, currentPage * 20)
              .map((item) => (
                <ElementItem
                  columns={columns}
                  key={item.id}
                  item={item}
                />
              ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <NotFound>{translate('no-results')}</NotFound>
              </td>
            </tr>
          )}
        </TableBody>
      </Table>

      <Pagination
        inverse
        currentPage={currentPage}
        total={filteredData.length || 0}
        handlePageChange={setCurrentPage}
      />

      <ControlsWrapper>
        <FormControls>
          <PrimaryButton
            type="button"
            onClick={() =>
              printPDF({
                name:
                  intl.formatMessage({ id: 'create-report-' + settings.type }) +
                  ` ${formatToReport(new Date())}`,
                columns: pdfCols,
                body: dataItems,
              })
            }
          >
            {translate('save-pdf')}
          </PrimaryButton>
          <CSVLink
            data={dataItems}
            headers={columns}
            separator={';'}
            filename={
              intl.formatMessage({ id: 'create-report-' + settings.type }) +
              ` ${formatToReport(new Date())}`
            }
          >
            <PrimaryButton type="button">{translate('save-csv')}</PrimaryButton>
          </CSVLink>
          <DefaultButton
            type="button"
            onClick={onClose}
          >
            {translate('cancel')}
          </DefaultButton>
        </FormControls>
      </ControlsWrapper>
    </ReportDataContainer>
  );
};

export default ReportData;

const ReportDataContainer = styled.div`
  margin-top: 32px;
`;

const TableHeadCell = styled.th`
  text-align: left;
  padding: 5px 10px 5px 0;
`;

const TableBody = styled.tbody``;

const TableHead = styled.thead`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

const Table = styled.table`
  margin: 1rem 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  tr td:last-child {
    width: 1%;
    white-space: nowrap;
  }
`;

const TableCell = styled.td`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  padding: 5px 10px 5px 0;
`;

const ElementItem: React.FC<{ item: BookingItem; columns: IColumn[] }> = ({
  item,
  columns,
}) => {
  const { isDesktop } = useResponsive();
  const objectTypes = useProjectStore((state) => state.objectTypes);

  const mapped = BookingMapper.toReport(item, columns);
  const mappedExport = BookingMapper.toExport(item, columns, objectTypes);

  if (!isDesktop)
    return (
      <MobileReportItem
        columns={columns}
        data={mappedExport}
      />
    );

  return (
    <tr>
      {mapped.map((col) => (
        <ProxyBookingCell
          key={col?.value + col?.type}
          column={col}
        />
      ))}
    </tr>
  );
};

const ProxyBookingCell = ({ column }) => {
  const { value, item, type } = column;
  const objectTypes = useProjectStore((state) => state.objectTypes);

  if (type === 'string') {
    return <TableCell>{value}</TableCell>;
  }

  if (type === 'date') {
    return (
      <TableCell>
        {value
          ? `${format(parseISO(value + '.000Z'), 'dd.MM.yyyy HH:mm')}`
          : ''}
      </TableCell>
    );
  }

  if (type === 'objectId') {
    const objectType = objectTypes.find((obj) => obj.value == value);
    return <TableCell>{objectType?.label}</TableCell>;
  }

  if (type === 'bookingType') {
    const bookingType =
      item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant';
    return <TableCell>{bookingTypeTranslate[bookingType]}</TableCell>;
  }

  if (type === 'location') {
    return <TableCell>{value[value.length - 1]}</TableCell>;
  }

  return <TableCell></TableCell>;
};

export class BookingMapper {
  public static toExport(
    item: BookingItem,
    columns: IColumn[],
    objectTypes: IObjectType[],
  ) {
    if (!columns) return [];

    let data = {};

    const fields = columns.forEach((col) => {
      const translated = propsMapperDictionary[col.key];

      if (!translated) return null;

      let candidate = item[translated.value];

      if (!candidate) return null;

      if (translated.type === 'bookingType') {
        const bookingType =
          item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant';

        data[col.key] = bookingTypeTranslate[bookingType];
        return;
      }

      if (translated.type === 'date') {
        data[col.key] = format(
          parseISO(candidate + '.000Z'),
          'dd.MM.yyyy HH:mm',
        );
        return;
      }

      if (translated.type === 'location') {
        data[col.key] = candidate[candidate.length - 1];
        return;
      }

      if (translated.type === 'objectId') {
        const objectType = objectTypes?.find((obj) => obj.value == candidate);
        data[col.key] = objectType?.label;
        return;
      }

      data[col.key] = candidate;
      return;
    });

    return data;
  }

  public static toReport(item: BookingItem, columns: IColumn[]) {
    if (!columns) return [];

    const fields = columns.map((col) => {
      const translated = propsMapperDictionary[col.key];

      if (!translated) return null;

      let candidate = item[translated.value];

      return {
        value: candidate,
        item,
        type: translated.type,
      };
    });

    return fields;
  }
}

const propsMapperDictionary = {
  user: {
    value: 'user',
    type: 'string',
  },
  name: {
    value: 'name',
    type: 'string',
  },
  objectType: {
    value: 'type_uid',
    type: 'objectId',
  },
  bookingType: {
    value: 'booking_type',
    type: 'bookingType',
  },
  startDate: {
    value: 'start',
    type: 'date',
  },
  endDate: {
    value: 'end',
    type: 'date',
  },
  layer: {
    value: 'location',
    type: 'location',
  },
};

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`;
