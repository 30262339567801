import { translate } from '@/i18n'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import Grid from '@/ui/components/Grid'
import { Field, useFormikContext } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Portal } from 'react-portal'
import { ModalSelectInputField } from '../shared/ModalSelectInputField'
import SelectUserModal from './SelectUserModal'
import styled from 'styled-components'
import Chevron from '@/components/icons/Chevron'

const SelectUserField = () => {
	const [isOpen, setOpen] = useState(false)
	const { setFieldValue } = useFormikContext()
	const intl = useIntl()

	const role = useUserStore((state) => state.role)

	const handleEmployeeSelection = () => {
		if (role == RolesEnum.Admin) {
			setOpen(true)
		}
	}

	const isDisabled = role !== RolesEnum.Admin

	return (
		<>
			<Grid item xs={12} md={4} onClick={handleEmployeeSelection}>
				<FormLabel>{translate('choose-employee')}</FormLabel>
				<FieldWrap>
					<Field
						name="user"
						$fullWidth
						type="text"
						placeholder={intl.formatMessage({ id: 'employee' })}
						component={ModalSelectInputField}
						disabled={isDisabled}
						style={{ opacity: isDisabled ? 0.5 : 1, pointerEvents: 'none' }}
					/>
					<Chevron />
				</FieldWrap>
			</Grid>
			<Portal>
				<SelectUserModal
					isOpen={isOpen}
					setFieldValue={setFieldValue}
					onClose={() => setOpen(false)}
				/>
			</Portal>
		</>
	)
}

export default SelectUserField

const FieldWrap = styled.div`
	position: relative;

	svg {
		position: absolute;
		top: 22px;
		transform: translateY(-50%);
		right: 10px;
		width: 18px;
		height: 18px;
		color: #828282;
		pointer-events: none;
	}
`
