import ArrowIcon from '@/components/icons/ArrowIcon'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

interface ToggleBookingGridProps {
	onClick: () => void
	label: React.JSX.Element | ''
	isActive: boolean
}

const ToggleBookingGrid: FC<ToggleBookingGridProps> = ({
	onClick: handleOnClick,
	label,
	isActive,
}) => {
	return (
		<Button onClick={handleOnClick} type="button">
			{label}
			<Icon $isActive={isActive}>
				<ArrowIcon />
			</Icon>
		</Button>
	)
}

export default ToggleBookingGrid

const Button = styled.button`
	outline: none;
	background-color: transparent;
	border: none;
	border-bottom: 1px dashed currentColor;
	padding-bottom: 2px;
	margin: auto;
	cursor: pointer;
`

const Icon = styled.span<{ $isActive?: boolean }>`
	display: inline-block;
	width: 18px;
	height: 18px;
	transform: rotate(-90deg);
	margin-left: 4px;

	${({ $isActive }) =>
		$isActive &&
		css`
			transform: rotate(90deg);
		`}
`
