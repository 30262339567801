import { api } from '../api';
import { ApiResponseType } from './types';

export const projectAPI = {
  metadata({ workspaceId, projectId }: ProjectMetadataDto) {
    return api
      .get<ProjectMetadataResponse>(
        `project/${workspaceId}/${projectId}/API?action=project_metadata`,
      )
      .then((res) => res.data);
  },
  tree({ workspaceId, projectId }: ProjectTreeDto) {
    return api
      .get<ProjectTreeResponse>(
        `project/${workspaceId}/${projectId}/API?action=project_tree`,
      )
      .then((res) => res.data);
  },
  getElements({ workspaceId, projectId, layerId }: ProjectLayerElementsDto) {
    return api
      .post<ProjectLayerElementsResponse>(
        `project/${workspaceId}/${projectId}/API?action=get_layer_view`,
        {
          id: layerId,
        },
      )
      .then((res) => res.data);
  },
  getNodeList({ workspaceId, projectId, page = 0, perPage = 20 }: NodeListDto) {
    const data = new FormData();
    data.append('perpage', String(perPage));
    data.append('page', String(page));

    return api
      .post<GetNodeListResponse>(
        `project/${workspaceId}/${projectId}/API?action=node_list`,
        data,
      )
      .then((res) => res.data);
  },
  getNodeInfo({ workspaceId, projectId, nodeId }: NodeInfoDto) {
    return api
      .get<GetNodeInfoResponse>(
        `project/${workspaceId}/${projectId}/API?action=get_node&id=${nodeId}`,
      )
      .then((res) => res.data);
  },
};

interface ProjectTreeDto {
  workspaceId: number;
  projectId: number;
}

interface ProjectLayerElementsDto extends ProjectTreeDto {
  layerId?: number;
}

type ProjectMetadataDto = ProjectTreeDto;

interface NodeInfoDto extends ProjectTreeDto {
  nodeId: number;
}

interface NodeListDto extends ProjectTreeDto {
  page?: number;
  perPage?: number;
}

export interface PropertyType {
  property_name: string;
  property_value: string;
  sort: number;
}

export type NodeInfoType = {
  id: number;
  parent: number;
  parent_name: string[];
  plugin_data: any;
  type_uid: string;
  name: string;
  info?: any;
  properties: PropertyType[];
  sort: number;
  x: number;
  y: number;
  class_version: string;
};
export interface GetNodeInfoResponse extends ApiResponseType {
  node_info: NodeInfoType;
}
export interface GetNodeListResponse extends ApiResponseType {
  items: NodeElement[];
  total: number;
}

export interface ProjectTreeResponse extends ApiResponseType {
  tree: ProjectTree[];
}

interface ElementInfo {
  type_name: string;
  id: number;
}

export interface NodeElement {
  id: string;
  type_uid: string;
  parent: string;
  name: string;
  sort: string;
  x: string;
  y: string;
  type_name: string;
}

export interface ProjectLayerElementsResponse extends ApiResponseType {
  points: ElementInfo[];
}

export interface ProjectTree {
  color: string;
  font_size: number;
  borderwidth: number;
  font_options: number;
  wrap_text: boolean;
  clip_text: boolean;
  scale_text: boolean;
  label_size: number;
  childs: ProjectTree[];
  plugin_data: any;
  id: number;
  key: string;
  name: string;
  type_uid: string;
  parent_id: number | null;
  parent_key: number | null;
  own_view: boolean;
  sort: number;
}

export interface ProjectMetadataResponse extends ApiResponseType {
  // TODO: Доделать тип
  metablock: MetablockData;
}
export interface MetablockNodes {
  [key: string]: MetadataNode;
  plugin_data: any;
}

export interface MetablockData {
  id: number;
  workspace_id: number;
  name: string;
  workspace_name: string;
  info: string;
  // layers: Layer;
  nodes: MetablockNodes;
  plugins: string[];
}

interface MetadataNode {
  background: string;
  border: string;
  radius: number;
  uid: string;
  name: string;
  anywhere: boolean;
  info?: any;
  containers: string[];
  icon: NodeIcon;
  plugin_data: any;
}

interface NodeIcon {
  sort: number;
  context_name: string;
  owner_id: string;
  image_id: string;
  mime: string;
  valid: boolean;
  extension: string;
  mime_safe: string;
}
