import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import CloseIcon from './icons/CloseIcon';
import Grid from './Grid';
import { animated, useTransition } from '@react-spring/web';
import { easePoly } from 'd3-ease';
import { useGlobalStore } from '@/stores/globalStore';
import { useMetadata } from '@/api/hooks/useMetadata';
import { ErrorBoundary } from 'react-error-boundary';
import { OfficeMapIcon } from './icons';
import { useLayerInfo } from '@/api/hooks/useLayerInfo';
import { linkLayerInfoProperties } from './layout/Sidebar/Layers/LayerInfoModal';
import { formatBooleanValue } from './SeatBar';
import { translate } from '@/i18n';
import DraggableControl from '@/components/draggable/draggable-control/DraggableControl';
import Draggable from 'react-draggable';
import LayerPrinter from '@/components/layer-printer/LayerPrinter';
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector';

const format = (str: string | undefined) => str || '';

const dispatchPrintLayer = (id: string | number) => {
  const event = new CustomEvent('print-layer', {
    detail: {
      layerId: id,
    },
  });
  document.dispatchEvent(event);
};

const LayerBar = () => {
  const [section, setSection] = useState<'info' | null>(null);

  // data selectors
  const layerModal = useGlobalStore((state) => state.layerModal);
  const setLayerModal = useGlobalStore((state) => state.setLayerModal);
  const exportLayer = useSettingsSelector(
    (settings) => settings.exportLayer,
    false,
  );
  // fetch data hook
  const { data } = useLayerInfo(layerModal);
  const { metadata } = useMetadata();

  const currentNode = useMemo(() => {
    if (data && metadata && metadata.layers) {
      return metadata.layers[data.info.type_uid];
    }
    return null;
  }, [data, metadata]);

  const props = linkLayerInfoProperties(
    currentNode?.plugin_data,
    data?.info?.plugin_data,
  );
  const onClose = () => setLayerModal(null);
  const place = data?.info.parent_name.join('. ') || '';

  useEffect(() => {
    if (!layerModal) {
      setTimeout(() => {
        setSection(null);
      }, 300);
    }

    if (layerModal) {
      setSection(null);
    }
  }, [layerModal]);

  const transitions = useTransition(layerModal, {
    from: { opacity: 0, translateY: 100, translateX: '-50%' },
    enter: { opacity: 1, translateY: 0, translateX: '-50%' },
    leave: { opacity: 0, translateY: 100, translateX: '-50%' },
    config: {
      duration: 300,
      easing: easePoly.exponent(2),
    },
  });

  const toggleSection = (name) => {
    if (section && section !== name) {
      setSection(name);
    } else if (section === null) {
      setSection(name);
    } else {
      setSection(null);
    }
  };

  // const refBody = useRef(null)
  // useOnClickOutside(refBody, onClose)

  return transitions((style, item) =>
    item ? (
      <Draggable handle=".handle">
        <Wrapper style={style}>
          <DraggableControl className="handle" />
          {!section && <Title>{format(data?.info.name)}</Title>}

          {section === 'info' && (
            <Close onClick={onClose}>
              <CloseIcon color="#000" />
            </Close>
          )}

          {!section && (
            <SectionsWrapper>
              <Section
                $active={section === 'info'}
                onClick={toggleSection.bind(null, 'info')}
              >
                <OfficeMapIcon />
                <SectionTitle $expanded={section === null}>
                  {translate('info')}
                </SectionTitle>
              </Section>
              <Section onClick={onClose}>
                <CloseIcon color="#000" />
                <SectionTitle $expanded={section === null}>
                  {translate('close')}
                </SectionTitle>
              </Section>
            </SectionsWrapper>
          )}

          <Container $expanded={section !== null}>
            {section === 'info' && (
              <Table style={{ marginTop: 24 }}>
                <tr>
                  <td>
                    <b>{format(data?.info.name)}</b>
                  </td>
                  <td></td>
                </tr>

                {currentNode?.name && (
                  <tr>
                    <td>{translate('type')}</td>
                    <td>{format(currentNode?.name)}</td>
                  </tr>
                )}

                {place && (
                  <tr>
                    <td>{translate('position')}</td>
                    <td>{format(place)}</td>
                  </tr>
                )}

                {data?.info.info && (
                  <tr>
                    <td>{translate('description')}</td>
                    <td>{format(data?.info.info)}</td>
                  </tr>
                )}

                <ErrorBoundary fallback={<></>}>
                  {props
                    .filter((v) => !v.name.startsWith('#'))
                    .map((property) => (
                      <tr key={property.uid}>
                        <td>{property.name}</td>
                        <td>{formatBooleanValue(property.value)}</td>
                      </tr>
                    ))}
                </ErrorBoundary>
              </Table>
            )}
            {exportLayer && (
              <Grid.Row $cols="2fr">
                <LayerPrinter
                  onClick={() => {
                    if (layerModal) {
                      dispatchPrintLayer(layerModal);
                    }
                  }}
                />
              </Grid.Row>
            )}
          </Container>
        </Wrapper>
      </Draggable>
    ) : (
      ''
    ),
  );
};

export default LayerBar;

const Table = styled.table`
  margin-top: 12px;

  td,
  th {
    text-align: left;
  }

  td {
    font-size: 12px;
    //width: 50%;
    vertical-align: middle;
    white-space: nowrap;
  }

  tr {
    padding: 1rem 0px;
  }

  tr td:first-child {
    padding: 4px 12px 4px 0;
    width: 30%;
    white-space: break-spaces;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  tr td:last-child {
    font-weight: 700;
    word-break: break-word;
    white-space: break-spaces;
    padding: 4px 0px 4px 4px;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  &::before {
    content: ' ';
    position: absolute;
    border-radius: 50%;
    top: -5px;
    left: -5px;
    width: 40px;
    height: 40px;
    background: #fff975;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`;

const Container = styled.div<{ $expanded: boolean }>`
  margin: 1rem 1.6rem;
  max-height: 0;
  height: auto;
  transition: all 0.5s;
  overflow: hidden;

  ${Grid.Row} {
    padding: 1rem 0;
  }

  ${({ $expanded }) =>
    $expanded &&
    css`
      max-height: 500px;
    `}
`;

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: #000000;
  font-weight: 600;
  padding: 10px 0;
`;

const Wrapper = styled(animated.div)`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  max-height: 100%;
  max-width: 400px;
  min-height: 90px;
  width: 100%;
  z-index: 9101;
  overflow-y: auto;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
`;

const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem 1rem 0 1rem;
`;

const Section = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 33.3%;
  cursor: pointer;
  position: relative;
  z-index: 10;

  &::before {
    content: ' ';
    position: absolute;
    border-radius: 50%;
    top: 0;
    width: 40px;
    height: 40px;
    background: #fff975;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s;
  }

  ${({ $active }) =>
    $active &&
    css`
      &::before {
        opacity: 1;
      }
    `}
  &:hover {
    &::before {
      opacity: 1;
    }
  }
`;

const SectionTitle = styled.div<{ $expanded: boolean }>`
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
  color: #000000;
  padding-top: 0.8rem;
  max-height: 0;
  height: auto;
  transition: all 0.5s;
  overflow: hidden;

  ${({ $expanded }) =>
    $expanded &&
    css`
      max-height: 30px;
    `}
`;
