import React, { FC } from 'react'

interface ArrowIconProps {
    color?: string;
}

const ArrowIcon: FC<ArrowIconProps> = ({ color }) => {
	return (
		<svg
			width="7"
			height="12"
			viewBox="0 0 7 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="6"
				width="1.41421"
				height="8.48527"
				transform="rotate(45 6 0)"
				fill={color ? color : 'currentColor'}
			/>
			<rect
				x="7"
				y="11"
				width="1.41421"
				height="8.48527"
				transform="rotate(135 7 11)"
				fill={color ? color : 'currentColor'}
			/>
		</svg>
	)
}

export default ArrowIcon
