import React, { FC } from 'react'

interface CloseIconProps {
    color?: string;
}

const CloseIcon: FC<CloseIconProps> = ({ color = 'white' }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5.17126" y="6.75818" width="2.39666" height="16.7766" rx="1.19833" transform="rotate(-44.0103 5.17126 6.75818)" fill={color} />
            <rect x="6.66071" y="18.6487" width="2.39666" height="16.7766" rx="1.19833" transform="rotate(-134.01 6.66071 18.6487)" fill={color} />
        </svg>
    )
}

export default CloseIcon;
