import React, { FC } from 'react';

interface IconProps {
  width?: number;
  height?: number;
}

const SearchIcon: FC<IconProps> = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0943 16.3792L13.6126 11.8952C14.6896 10.6016 15.2266 8.94236 15.112 7.26269C14.9974 5.58301 14.24 4.01219 12.9973 2.87702C11.7547 1.74184 10.1224 1.12971 8.4401 1.16795C6.75781 1.20619 5.15502 1.89187 3.96516 3.08235C2.77529 4.27283 2.08996 5.87644 2.05174 7.55959C2.01351 9.24274 2.62534 10.8758 3.75993 12.1192C4.89452 13.3625 6.46453 14.1203 8.14335 14.2349C9.82217 14.3496 11.4805 13.8123 12.7735 12.7347L17.2552 17.2187L18.0943 16.3792ZM3.25816 7.71874C3.25816 6.66185 3.57141 5.62869 4.15828 4.74991C4.74516 3.87114 5.57931 3.18622 6.55526 2.78176C7.5312 2.37731 8.6051 2.27148 9.64115 2.47767C10.6772 2.68386 11.6289 3.1928 12.3758 3.94014C13.1228 4.68748 13.6315 5.63964 13.8376 6.67623C14.0436 7.71281 13.9379 8.78726 13.5336 9.76371C13.1294 10.7401 12.4448 11.5747 11.5665 12.1619C10.6882 12.7491 9.65553 13.0625 8.59917 13.0625C7.18313 13.0609 5.82554 12.4974 4.82424 11.4956C3.82295 10.4938 3.25973 9.13551 3.25816 7.71874Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchIcon;
