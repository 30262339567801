import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { BookingService } from '../services/booking.service';

export const useBookingsForLayer = (
  layer: number | null,
  start?: Date,
  end?: Date,
) => {
  const { workspaceId, projectId } = useProject();
  const { enqueueToast } = useToast();

  const layerId = Number(layer);

  return useQuery(
    ['bookings_for_layer', workspaceId, projectId, layer, start, end],
    // ['bookings_for_layer'],
    () =>
      BookingService.getBookingsForLayer({
        workspaceId,
        projectId,
        layerId,
        start,
        end,
      }),
    {
      enabled: !!workspaceId && !!projectId && !!layerId,
      select: ({ data }) => data,
      onError: () => {
        enqueueToast(
          {
            title: 'Ошибка!',
            message: 'Не удалось загрузить бронирования по выбранному периоду',
          },
          { variant: 'error' },
        );
      },
    },
  );
};
