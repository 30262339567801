import React, { useEffect } from 'react';
import { useMapStore } from '@/stores/mapStore';

export default function Tooltip() {
  const setTooltipPoint = useMapStore((state) => state.setTooltipPoint);

  const onMouseMove = (ev: MouseEvent) => {
    const ttPoint = {
      x: ev.pageX,
      y: ev.pageY - 110,
    };

    setTooltipPoint(ttPoint);
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);

    return function () {
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  return null;
}
