import React, { useState, useCallback, useEffect } from 'react';
import { nanoid } from 'nanoid';
import ToastContainer from './ToastContaner';
import { ToastContentType, ToastOptionsType } from './Toast';

type ToastContextType = {
  enqueueToast: (
    content: string | ToastContentType,
    options?: ToastOptionsType,
  ) => void;
  dismissToast: (id: string) => void;
};

export const ToastContext = React.createContext<ToastContextType>({
  enqueueToast: () => {},
  dismissToast: () => {},
});

const ToastProvider = ({ children, maxStack = 0 }) => {
  const [toasts, setToasts] = useState<any[]>([]);

  const enqueueToast = useCallback(
    (content, options) => {
      const contentObj =
        typeof content === 'object'
          ? {
              title: content.title,
              message: content.message,
            }
          : {
              title: null,
              message: content,
            };

      setToasts((toasts) => [
        ...toasts,
        { id: nanoid(), content: contentObj, options },
      ]);
    },
    [setToasts],
  );

  const dismissToast = useCallback(
    (id) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts],
  );

  return (
    <ToastContext.Provider value={{ enqueueToast, dismissToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
