import React from 'react';
import BaseLayout from '../components/layout/BaseLayout';
import styled from 'styled-components';
import whiteLogo from '../images/themes/blue/logo.png';
import greyLogo from '../images/themes/white/logo.png';
import useRouteQuery from '../hooks/useRouteQuery';
import LoginForm from './../components/LoginForm';
import { FormattedMessage } from 'react-intl';
import { translate } from '@/i18n';

const LoginContainer: React.FC = (props) => {
  const query = useRouteQuery();

  return (
    <BaseLayout title="Авторизация">
      <Wrapper>
        <Container>
          <Brand>
            <BrandImage>
              <img
                src={whiteLogo}
                alt="white logo"
              />
            </BrandImage>
            <BrandName>{translate('brand-name')}</BrandName>
          </Brand>

          <Content>
            <AuthContainer>
              <Title>{translate('auth_auth-text')}</Title>

              <div style={{ margin: '1.6rem 0' }}>
                <LoginForm
                  workspaceId={query.get('workspace_id')}
                  projectId={query.get('project_id')}
                />
              </div>
            </AuthContainer>
          </Content>
        </Container>
      </Wrapper>
    </BaseLayout>
  );
};

export default LoginContainer;

const AuthContainer = styled.div`
  max-width: 300px;
  width: 100%;
`;
const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.text.primary};
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 20px 0;
`;

const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.background.primary};
  width: 100%;
  padding: 10px;
  display: flex;
`;
const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const BrandImage = styled.div`
  margin-right: 16px;
`;
const Brand = styled.div`
  display: flex;
  align-items: center;
`;
const BrandName = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.primary};
`;
