import { useProjectStore } from '@/stores/projectStore';
import React from 'react';
import User from './User';

const UserBar = () => {
  const fullMode = useProjectStore((state) => state.fullMode);

  if (!fullMode) return null;

  return <User />;
};

export default UserBar;
