import { Button } from '@/ui/components/Button/Button';
import React from 'react';
import styled from 'styled-components';
import BaseLayout from './layout/BaseLayout';
import { Link } from 'react-router-dom';
import { translate } from '@/i18n';

const NotFound: React.FC = () => {
  return (
    <BaseLayout>
      <Wrapper>
        <Content>
          <NotFoundText>{translate('project-not-found')} :(</NotFoundText>
          <Link to="/project/1/1">
            <Button>{translate('go-to-test-project')}</Button>
          </Link>
        </Content>
      </Wrapper>
    </BaseLayout>
  );
};

export default NotFound;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.palette.background.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const NotFoundText = styled.h1`
  font-weight: 700;
  font-size: 3rem;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
