import React from 'react';

const UsersIcon = ({ width = 31, height = 28 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="hover-alt"
        d="M0.552216 23.9732C0.552216 21.9012 2.65146 18.3965 9.33529 18.3965C16.0191 18.3965 18.1184 21.9012 18.1184 23.9732V27.1193H0.552216V23.9732Z"
        fill="#2C2C2C"
        stroke="white"
      />
      <path
        className="hover-alt"
        d="M14.057 11.212C14.057 13.9784 11.9215 16.1811 9.33529 16.1811C6.7491 16.1811 4.61356 13.9784 4.61356 11.212C4.61356 8.44559 6.7491 6.24292 9.33529 6.24292C11.9215 6.24292 14.057 8.44559 14.057 11.212Z"
        fill="#2C2C2C"
        stroke="white"
      />
      <path
        className="hover-alt"
        d="M7.72137 23.1429C7.72137 20.5174 10.3702 16.1824 18.6184 16.1824C26.8665 16.1824 29.5153 20.5174 29.5153 23.1429V27.1192H7.72137V23.1429Z"
        fill="#2C2C2C"
        stroke="white"
      />
      <path
        className="hover-alt"
        d="M24.5292 7.47583C24.5292 10.93 21.8612 13.6903 18.6184 13.6903C15.3755 13.6903 12.7076 10.93 12.7076 7.47583C12.7076 4.02161 15.3755 1.26135 18.6184 1.26135C21.8612 1.26135 24.5292 4.02161 24.5292 7.47583Z"
        fill="#2C2C2C"
        stroke="white"
      />
    </svg>
  );
};

export default UsersIcon;
