const LayerIcon = () => {
  return (
    <svg
      width="32"
      height="30"
      viewBox="0 0 44 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 18.5L1 21L22 31.5L43 21L38 18.5M5.5 27.5L1 30L22 40L43 30L38.5 27.5M1.5 12L22 1.5L42.5 12L22 22.5L1.5 12Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LayerIcon;
